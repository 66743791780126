<template>
  <div>
    <Modal :value="visible"
           title="选择商品"
           footer-hide
           width="980"
           @on-visible-change="onVisibleChange">
      <div class="selectGoods">
        <div class="selectGoods-topbar">
          <div class="flex a-center">
            <Select v-model="type"
                    placeholder="农药类别"
                    label-in-value
                    @on-change="onChangeType"
                    style="width:120px;margin-right:24px">
              <Option v-for="item in typeArr"
                      :value="item.id"
                      :label="item.name"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <Input v-model="searchValue"
                   placeholder="商品名称"
                   search
                   @on-search="onSearch"
                   style="width:280px" />
          </div>
          <Button @click.stop="onClickCreateGoods">新建商品</Button>
        </div>
        <Table :height="500"
               border
               :columns="columns"
               :data="data"
               @on-row-click="onRowClick">
          <template slot-scope="{ row }"
                    slot="operation">
            <Button @click.stop="onClickEditGoods(row)"
                    type="text"
                    style="color: #0652DD;width:40px;text-align: center;padding: 0">查看</Button>
          </template>
        </Table>
        <Page class="m-t-10"
              :total="totalPage"
              :current="pageNum"
              :page-size="pageSize"
              show-total
              show-sizer
              show-elevator
              @on-change="onPageChange"
              @on-page-size-change="onPageSizeChange" />
        <div class="selectGoods-btn">
          <Button type="primary"
                  class="m-r-25"
                  :disabled="!selectGoods"
                  @click.stop="onClickConfirm">确认</Button>
          <Button @click.stop="onClickCancle">取消</Button>
        </div>
      </div>
    </Modal>
    <GoodsDrawer :width="drawerWidth"
                 v-model="goodsVisible"
                 :goodsId="goodsId"
                 :type="operType"
                 @on-click-confirm="onClickConfirmGoods"
                 @on-click-delete="onClickDetailDelete"></GoodsDrawer>
  </div>
</template>

<script>
import { getGoodsList, getLicenseTypeList } from '../../services/goodsapi';
import GoodsDrawer from './GoodsDrawer';
export default {
  components: { GoodsDrawer },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean,
    drawerWidth: Number
  },
  data () {
    return {
      type: 0,
      typeName: '',
      typeArr: [
        {
          id: 0,
          name: '全部类别'
        }
      ],
      searchValue: '',
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      columns: [
        {
          title: ' ',
          width: 45,
          align: 'center',
          render: (h, params) => {
            return h('Radio', {
              props: {
                value: params.row.checked
              },
              on: {
                'on-change': () => {
                  this.onRowClick(params.row, params.index)
                }
              }
            });
          }
        },
        {
          title: '商品名称',
          key: 'goodsName',
          tooltip: true,
          width: 150
        },
        {
          title: '农药名称',
          key: 'mainIngredient',
          tooltip: true,
          width: 160
        },
        {
          title: '规格',
          key: 'spec',
          tooltip: true,
          minWidth: 120
        },
        {
          title: '持证企业',
          key: 'entName',
          tooltip: true,
          minWidth: 180
        },
        {
          title: '操作',
          slot: 'operation',
          width: 60,
          align: 'center'
        }
      ],
      data: [],
      selectGoods: null,

      goodsId: 0,
      goodsVisible: false,
      operType: 0
    }
  },
  computed: {},
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getGoodsList()
      this.getLicenseTypeList()
    },
    // 获取农药类别
    getLicenseTypeList () {
      getLicenseTypeList({ type: 1 }).then(res => {
        this.typeArr = this.typeArr.concat(res.data)
      })
    },
    onChangeType (obj) {
      this.typeName = obj.label
      this.onSearch()
    },
    onSearch () {
      this.pageNum = 1
      this.getGoodsList()
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getGoodsList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getGoodsList()
    },
    // 获取商品列表
    getGoodsList () {
      let params = {
        goodsType: 1,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      if (this.type) params.childTypeName = this.typeName
      if (this.searchValue) params.goodsName = this.searchValue
      getGoodsList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list.map(item => {
          item.checked = false
          item.mainIngredient = item.extra.mainIngredient
          item.entName = item.holderList.length > 0 ? item.holderList[0].entName : ''
          return item
        })
      })
    },
    // 点击某一行
    onRowClick (row, index) {
      this.data.forEach(item => {
        this.$set(item, 'checked', false);
      });
      this.data[index].checked = true;
      this.selectGoods = row;
    },
    // 点击确定
    onClickConfirm () {
      this.$emit('change', false)
      this.$emit('on-click-confirm', this.selectGoods)
    },
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
      this.$emit('on-click-cancle')
    },
    // 显示状态改变
    onVisibleChange (boo) {
      if (!boo) this.onClickCancle()
    },
    // 点击新建商品
    onClickCreateGoods () {
      this.goodsId = 0
      this.operType = 1
      this.goodsVisible = true
      this.$emit('change', false)
    },
    // 点击编辑商品
    onClickEditGoods (row) {
      this.goodsId = row.goodsId
      this.operType = 3
      this.goodsVisible = true
      this.$emit('change', false)
    },
    // 点击保存
    onClickConfirmGoods () {
      this.getGoodsList()
    },
    // 点击详情内的删除
    onClickDetailDelete () {
      this.getGoodsList()
    },
  },
}
</script>

<style lang='scss' scoped>
.selectGoods {
  .selectGoods-topbar {
    padding: 4px 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  ::v-deep {
    .ivu-table-cell {
      padding: 0 8px;
    }
  }
  .selectGoods-btn {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
}
</style>
