import config from '../config/constantconfig'

export const dowloadFile = (url) => {
  let OSS = require('ali-oss')
  const ossTypeConfig = config.fileOssInfo
  const client = new OSS({
    region: ossTypeConfig.region,
    accessKeyId: ossTypeConfig.accessKeyId,
    accessKeySecret: ossTypeConfig.accessKeySecret,
    bucket: ossTypeConfig.bucket
  });
  // http://image.test.laodao.so/file/tradeapp/qrcode/trace/e2638f90-c7f5-3794-9322-4c43c9ecae98.txt
  let urlArr = url.split('/')

  // 配置响应头实现通过URL访问时自动下载文件，并设置下载后的文件名。
  const filename = urlArr[urlArr.length - 1] // filename为自定义下载后的文件名。
  let fileUrl = ''
  urlArr.forEach((item, index) => {
    if (index !== 0 && index !== 1 && index !== 2) {
      fileUrl += '/' + item
    }
  });
  const response = {
    'content-disposition': `attachment; filename=${encodeURIComponent(filename)}`
  }
  // object-key表示从OSS下载文件时需要指定包含文件后缀在内的完整路径，例如abc/efg/123.jpg。
  const dowloadUrl = client.signatureUrl(fileUrl, {
    response
  });
  return dowloadUrl
}