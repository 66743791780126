<template>
  <div class="main"
       ref="main">
    <div class="title">二维码生成器</div>
    <div class="content">
      <div class="form">
        <ul class="form-ul">
          <li class="form-li">
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>商品名称:</span>
              </div>
              <div class="form-value">
                <Input v-model="goodsName"
                       :disabled="taskStatus===1"
                       style="width:240px;padding-right: 32px;" />
                <span class="form-span"
                      @click.stop="taskStatus===1?'':selectGoods()">选择</span>
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>农药名称:</span>
              </div>
              <div class="form-value">
                <Input v-model="pestName"
                       disabled
                       style="width:240px" />
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>持证企业:</span>
              </div>
              <div class="form-value">
                <Input v-model="entName"
                       disabled
                       style="width:240px" />
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>生产企业:</span>
              </div>
              <div class="form-value">
                <Input v-model="prodEntName"
                       :disabled="taskStatus===1"
                       style="width:240px" />
              </div>
            </div>
          </li>
          <li class="form-li">
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>生产类型:</span>
              </div>
              <div class="form-value">
                <Select v-model="prodType"
                        :disabled="taskStatus===1"
                        @on-change="onChangeProdType"
                        style="width:240px">
                  <Option v-for="item in prodTypeArr"
                          :value="item.id"
                          :key="item.id">{{ item.name }}</Option>
                </Select>
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>商品规格:</span>
              </div>
              <div class="form-value">
                <Select v-model="goodsSpec"
                        placeholder="请选择规格"
                        :disabled="goodsSpecArr.length===0||taskStatus===1"
                        style="width:240px">
                  <Option v-for="item in goodsSpecArr"
                          :value="item.id"
                          :key="item.id">{{ item.name }}</Option>
                </Select>
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>生产批次:</span>
              </div>
              <div class="form-value">
                <Select v-model="prodBatch"
                        :disabled="taskStatus===1"
                        style="width:240px">
                  <Option v-for="item in prodBatchArr"
                          :value="item.id"
                          :key="item.id">{{ item.name }}</Option>
                </Select>
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>生产日期:</span>
              </div>
              <div class="form-value">
                <Select v-model="prodData"
                        :disabled="taskStatus===1"
                        style="width:240px">
                  <Option v-for="item in prodDataArr"
                          :value="item.id"
                          :key="item.id">{{ item.name }}</Option>
                </Select>
              </div>
            </div>
            <div class="form-item">
              <div class="form-key">
                <span style="color:#D0021B">*</span>
                <span>开码数量:</span>
              </div>
              <div class="form-value">
                <Input v-model="yardsNumber"
                       :disabled="taskStatus===1"
                       @on-keyup="yardsNumber=formatNumber(yardsNumber)"
                       @on-focus="onFocusYardsNumber"
                       @on-blur="onBlurYardsNumber"
                       style="width:240px"
                       class="big" />
              </div>
            </div>
            <Button type="primary"
                    class="form-btn"
                    :disabled="taskStatus===1||disabled"
                    @click.stop="disabled?'':openYards()">确认</Button>
            <Button type="primary"
                    class="form-btn"
                    @click.stop="downloadYardsData"
                    v-show="yardDataUrl">下载数据包</Button>
            <Button type="primary"
                    class="form-btn"
                    :disabled="taskStatus===1"
                    @click.stop="onClickClear">清空</Button>
          </li>
        </ul>
        <div class="table">
          <div class="plan">
            <template v-if="taskStatus">
              <div class="plan-title">
                <span style="color:#0652DD">{{finishYardsNumber}}</span>
                <span>/{{totalYardsNumber}}</span>
              </div>
              <div class="plan-con">
                <span>生成进度</span>
                <Progress :percent="getProductPlan"
                          :stroke-width="14"
                          style="width:320px" />
              </div>
            </template>
          </div>
          <Table :height="tableHeight"
                 :columns="columns"
                 :data="data"></Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
    <SelectGoodsModal v-model="selectGoodsVisible"
                      :drawerWidth="drawerWidth"
                      @on-click-confirm="getSelectGoods"
                      @on-click-cancle="cancleSelectGoods"></SelectGoodsModal>
  </div>
</template>

<script>
import { getGoodsDetail } from '../../../services/goodsapi';
import { openYards, getProductTaskInfo, getProductTaskList } from '../../../services/yardsapi';
import { dowloadFile } from '../../../utils/ossdowload';
import SelectGoodsModal from '../../../components/product/SelectGoodsModal';
export default {
  components: { SelectGoodsModal },
  data () {
    return {
      goodsId: 0,
      goodsName: '',
      pestName: '',
      entName: '',
      prodEntName: '',
      prodType: 1,
      prodTypeArr: [
        {
          id: 1,
          name: '持证人生产'
        }, {
          id: 2,
          name: '委托生产'
        }, {
          id: 3,
          name: '委托分装'
        },
      ],
      goodsSpec: 0,
      goodsSpecArr: [],
      prodBatch: 1,
      prodBatchArr: [
        {
          id: 1,
          name: '见包装喷码'
        },
        {
          id: 2,
          name: '激活后显示日期'
        },
      ],
      prodData: 1,
      prodDataArr: [
        {
          id: 1,
          name: '见包装喷码'
        },
        {
          id: 2,
          name: '激活后显示日期'
        },
      ],
      yardsNumber: 0,
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      columns: [
        {
          title: '序列号',
          width: 180,
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '生码编号',
          key: 'code',
          minWidth: 200
        },
        {
          title: '二维码数据',
          key: 'qrCode',
          minWidth: 300
        }
      ],
      data: [],
      ruleId: 0,
      taskTimer: null,
      finishYardsNumber: 0,
      totalYardsNumber: 0,
      taskStatus: 0,
      yardDataUrl: '',
      selectGoodsVisible: false,
      drawerWidth: 0,
      disabled: false
    }
  },
  computed: {
    getProductPlan () {
      return parseInt(this.finishYardsNumber / this.totalYardsNumber * 100)
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    clearInterval(this.taskTimer)
    this.taskTimer = null
  },
  methods: {
    init () {
      this.tableHeight = window.innerHeight - 480
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 480
      }
      this.drawerWidth = this.$refs.main.offsetWidth
      if (this.$route.params.id) {
        this.getGoodsDetail(this.$route.params.id)
      }
    },
    // 只能输入数字
    formatNumber (str) {
      return str.replace(/[^\d]/g, '')
    },
    onChangeProdType (id) {
      if (id === 1) {
        this.prodEntName = this.entName
      } else {
        this.prodEntName = ''
      }
    },
    onFocusYardsNumber () {
      if (this.yardsNumber === 0) {
        this.yardsNumber = ''
      }
    },
    onBlurYardsNumber () {
      if (this.yardsNumber === '') {
        this.yardsNumber = 0
      }
    },
    // 选择商品
    selectGoods () {
      this.$once('selectGoods', query => {
        this.getGoodsDetail(query.goodsId)
      })
      this.selectGoodsVisible = true
    },
    // 获取商品详情
    getGoodsDetail (id) {
      getGoodsDetail(id).then(res => {
        if (res.code === 0) {
          let data = res.data
          this.goodsId = data.id
          this.goodsName = data.name
          this.pestName = data.extra.name
          if (data.holderList.length > 0) {
            this.entName = data.holderList[0].entName
          }
          if (data.specList.length > 0) {
            this.goodsSpecArr = data.specList.map(item => {
              return {
                id: item.specId,
                name: item.specDesc
              }
            })
            this.goodsSpec = this.goodsSpecArr[0].id
          }
          if (this.prodType === 1) {
            this.prodEntName = this.entName
          } else {
            this.prodEntName = ''
          }
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    // 获取选择的商品
    getSelectGoods (obj) {
      this.$emit('selectGoods', obj)
    },
    // 取消选择商品
    cancleSelectGoods () {
      this.$off('selectGoods')
    },
    // 生成二维码
    openYards () {
      if (!this.goodsName) return this.$Message.info('请输入商品名称')
      if (!this.entName) return this.$Message.info('请去商品信息完善持证企业')
      if (!this.prodEntName) return this.$Message.info('请输入生产企业')
      if (!this.prodType) return this.$Message.info('请选择生产类型')
      if (!this.goodsSpec) return this.$Message.info('请选择商品规格')
      if (!this.prodBatch) return this.$Message.info('请输入生产批次')
      if (!this.prodData) return this.$Message.info('请选择生产日期')
      if (!this.yardsNumber) return this.$Message.info('请输入开码数量')
      let reg = /^-?\d+$/
      if (!reg.test(this.yardsNumber)) return this.$Message.info('请输入正确的开码数量')
      let params = {
        goodsId: this.goodsId,
        goodsName: this.goodsName,
        productEntName: this.prodEntName,
        produceType: this.prodType,
        specId: this.goodsSpec,
        produceDateType: this.prodData,
        codeQty: this.yardsNumber,
        productSerialType: this.prodBatch
      }
      this.disabled = true
      openYards(params).then(res => {
        if (res.code === 0) {
          this.ruleId = res.data.ruleId
          this.createProductTask()
        } else {
          this.disabled = false
          if (res.msg) return this.$Message.info(res.msg)
        }
      }).catch(err => {
        this.disabled = false
      })
    },
    // 创建生产任务
    createProductTask () {
      if (!this.ruleId) return
      this.taskStatus = 0
      this.data = []
      this.pageNum = 1
      this.totalPage = 0
      this.yardDataUrl = ''
      clearInterval(this.taskTimer)
      this.taskTimer = setInterval(() => {
        getProductTaskInfo(this.ruleId).then(res => {
          this.disabled = false
          if (res.code === 0) {
            this.finishYardsNumber = res.data.finishQty
            this.totalYardsNumber = res.data.genQty
            this.taskStatus = res.data.genStatus
            if (res.data.genStatus === 2 && (this.data.length >= this.pageSize || this.totalPage === this.totalYardsNumber)) {
              clearInterval(this.taskTimer)
              this.taskTimer = null
              this.yardDataUrl = res.data.dataPackageUrl
            }
          } else {
            if (res.msg) this.$Message.info(res.msg)
            clearInterval(this.taskTimer)
            this.taskTimer = null
          }
        }).catch(err => {
          this.disabled = false
          clearInterval(this.taskTimer)
          this.taskTimer = null
        })
        // if (this.data.length < this.pageSize) {
        this.getProductTaskList()
        // }
      }, 1000)
    },
    // 获取生产任务列表
    getProductTaskList () {
      let params = {
        ruleId: this.ruleId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      getProductTaskList(params).then(res => {
        this.totalPage = res.data.total
        this.data = res.data.list
      })
    },
    // 下载数据包
    downloadYardsData () {
      // http://image.test.laodao.so/file/tradeapp/qrcode/trace/e2638f90-c7f5-3794-9322-4c43c9ecae98.txt
      let dowloadUrl = dowloadFile(this.yardDataUrl)
      // 创建a标签
      const link = document.createElement('a')
      // href链接
      link.setAttribute('target', '_blank')
      link.setAttribute('href', dowloadUrl)
      // 自执行点击事件
      link.click()
    },
    // 点击清空数据
    onClickClear () {
      this.$Modal.confirm({
        title: '清空数据',
        content: '<p>是否清空数据？</p>',
        onOk: () => {
          this.clearYardsData()
        }
      })
    },
    // 清空数据
    clearYardsData () {
      this.goodsId = 0
      this.goodsName = ''
      this.pestName = ''
      this.entName = ''
      this.prodEntName = ''
      this.prodType = 1
      this.taskStatus = 0
      this.goodsSpec = 0
      this.goodsSpecArr = []
      this.prodBatch = 1
      this.prodData = 1
      this.yardsNumber = 0
      this.data = []
      this.pageNum = 1
      this.totalPage = 0
      this.yardDataUrl = ''
    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getProductTaskList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getProductTaskList()
    },
  },
}
</script>

<style lang='scss' scoped>
.main {
  height: 100%;
  background-color: #e6e6f2;
  .title {
    font-size: 22px;
    line-height: 66px;
    text-align: center;
    background-color: #fff;
  }
  .content {
    padding: 20px;
    .form {
      padding: 0 10px 10px;
      background-color: #314a76;
      .form-ul {
        padding: 0 10px;
        .form-li {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          color: #fff;
          // &:not(:last-child) {
          //   border-bottom: 1px dotted #fff;
          // }
          .form-item {
            display: flex;
            align-items: center;
            margin-right: 70px;
            padding: 15px 0;
            .form-key {
              font-size: 16px;
              flex-shrink: 0;
              margin-right: 10px;
              span:first-child {
                width: 8px;
                display: inline-block;
              }
            }
            .form-value {
              display: flex;
              align-items: center;
              border-bottom: 1px solid #fff;
              position: relative;
              ::v-deep {
                .ivu-input {
                  font-size: 16px;
                  line-height: 32px;
                  background-color: transparent;
                  border: none;
                  color: #fff;
                  &:focus {
                    border: none;
                    box-shadow: none;
                  }
                }
                .ivu-select-selection {
                  background-color: transparent;
                  border: none;
                  color: #fff;
                  span {
                    font-size: 16px;
                    line-height: 32px;
                  }
                  &:focus {
                    border: none;
                    box-shadow: none;
                  }
                }
                .form-span {
                  font-size: 14px;
                  padding: 0 4px;
                  background-color: #314a76;
                  color: #fff;
                  position: absolute;
                  right: 0;
                  cursor: pointer;
                }
              }
              .big ::v-deep input {
                font-size: 26px;
              }
            }
          }
          .form-btn {
            margin-right: 25px;
          }
          ::v-deep .ivu-btn {
            line-height: 2;
          }
        }
      }
      .table {
        background-color: #fff;
        padding-bottom: 10px;
        .plan {
          padding-top: 10px;
          padding-right: 10px;
          text-align: right;
          .plan-title {
            font-size: 14px;
            padding-right: 30px;
          }
          .plan-con {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
              font-size: 14px;
              line-height: 20px;
              color: #666;
            }
          }
        }
      }
    }
  }
}
</style>